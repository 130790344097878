import React from "react";
import { Layout } from '../../components';

import PropTypes from "prop-types";

const Component = (props) => {
  return (
    <Layout>
      <div>
        Success!
      </div>
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
